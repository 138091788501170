//
export var footerData = [{
  header: 'Navigation',
  items: [{
    name: 'Home',
    to: '/'
  }, {
    name: 'About',
    to: '/about'
  }, {
    name: 'Explore',
    to: '/explore'
  }]
}, {
  header: 'Legal',
  items: [{
    name: 'Privacy Policy',
    to: '/privacy'
  }, {
    name: 'Terms of Service',
    to: '/terms'
  }]
}];